//eslint-disable-next-line
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/

export const passwordRules = [
  { message: "Minst 1 siffra!", short: "1 siffra", regex: /[0-9]+/ },
  { message: "Minst 8 tecken!", short: "8 tecken!", regex: /.{8,}/ },
  { message: '1 liten bokstav', short: "1 liten", regex: /[a-z]+/ },
  { message: "1 stor bokstav", short: "1 stor", regex: /[A-Z]+/ }
];

export const titleRules = { message: "Minst 2 bokstäver!", short: "Minst 2 bokstäver", regex: /.{2,}/ }
export const companyRules = { message: "Minst 2 bokstäver!", short: "Minst 2 bokstäver", regex: /.{2,}/ }
export const organisationRules = { message: "Felaktig nummer!", short: "Felaktig org!", regex: /^(\d{1})(\d{5})-(\d{4})$/ }
export const phoneNumberRules = { message: "Felaktig telefon!", short: "Felaktig tel!", regex: /^(((|(\+){1})46)|0)7[\d]{8}/ }
