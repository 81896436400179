<template>
    <div class="text-center login">
        <div>
            <div class="login">
                <form @submit.prevent novalidate>
                    <label style="color: rgb(245, 110, 110)" for="errorMessage">{{
                            errorDisplay
                    }}</label>
                    <div class="form-group mb-4">
                        <input type="email" class="form-control mt-3" aria-describedby="emailHelp"
                            :placeholder="$t('auth.email')" v-model="email" />
                        <!-- Lägg gärna nån länk här som skickar kunden till kontaktsida. -->
                    </div>
                    <div class="form-group mb-1">
                        <input type="password" class="form-control" :placeholder="$t('auth.password')"
                            v-model="password" />
                    </div>
                    <div class="text-start">
                        <a @click="$emit('toggle-password-reset')" class="fw-light forgot-password" href="#">
                            <small>{{ $t('auth.forgotpassword') }}</small></a>
                    </div>

                    <div class="form-group mt-3 row">
                        <div class="col-lg-8 col-md-7 col-sm-7 order-sm-2 mb-2">
                            <button type="submit" class="btn btn-primary w-100" @click="onLoginClick()">
                                {{ $t('auth.login') }}
                            </button>
                        </div>
                        <div class="col-lg-4 col-md-5 col-sm-5 order-sm-1">
                            <button type="button" class="btn btn-secondary w-100" @click="$emit('toggle-signup')">
                                {{ $t('auth.createaccount') }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Login',
    props: {
        errorMessage: {
            type: String,
        },
    },
    data() {
        return {
            email: '',
            password: '',
        }
    },
    methods: {
        async onLoginClick() {
            this.$emit('do-login', {
                email: this.email,
                password: this.password,
            })
        },
    },
    computed: {
        errorDisplay() {
            const err = this.errorMessage
            let message = ''
            if (err.getError?.login) {
                let errCode = err.getError?.login.code
                console.log(errCode)
                message =
                    errCode === 'auth/invalid-email'
                        ? 'Fel format på mailet'
                        : 'Uppgifterna är inte korrekta!'
            }
            return message
        },
    },
}
</script>

<style scope>
.login .btn {
    padding-top: 13px;
    padding-bottom: 13px;
}

.forgot-password {
    color: rgb(92, 92, 92);
}

.forgot-password:hover {
    color: rgb(40, 40, 40);
}
</style>
