<template>
    <div class="login">
        <div>
            <div class="login">
                <form @submit.prevent>
                    <div class="form-group mb-3">
                        <input type="email" class="form-control" aria-describedby="emailHelp" placeholder="Email"
                            v-model="email" />
                        <!-- Lägg gärna nån länk här som skickar kunden till kontaktsida. -->
                    </div>
                    <p v-if="statusMessage">{{ statusMessage }}</p>
                    <button type="submit" class="btn btn-primary w-100 mb-2" @click="resetPassword()">Återställ
                        lösenord</button>

                    <a @click="$emit('toggle-login-form')" href="#">Logga in här!</a>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Forgot",
    data() {
        return {
            email: ''
        }
    },
    props: {
        status: {
            type: Object,
            default: () => { }
        },
    },
    computed: {
        statusMessage() {
            return this.status?.message;
        },
        statusSucceeded() {
            return this.status?.code === 'Success';
        }
    },
    methods: {
        resetPassword() {
            this.$emit('do-reset', { email: this.email })
        }
    }
};
</script>

<style scope>

</style>
