<template>
    <div>
        <div class="progress mb-3 mt-3">
            <div v-if="registerStep" class="progress-bar" role="progressbar" style="width: 50%" aria-valuenow="50"
                aria-valuemin="0" aria-valuemax="100"></div>
            <div v-else class="progress-bar" role="progressbar" style="width: 100%" aria-valuenow="100"
                aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <form @submit.prevent novalidate>
            <div v-if="registerStep">
                <!-- <div class="mb-3">
                    <label for="title">{{ $t('auth.roleincompany') }}</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="title"
                            :placeholder="$t('auth.roleincompany_placeholder')" required v-model="title"
                            :class="{ 'is-invalid': !isRequired.title }" />
                    </div>
                    <label style="font-size: 0.8rem" class="text-muted" v-if="!isRequired.title">{{
                            $t('validation.min_char', { amount: 2 })
                    }}</label>
                </div> -->
                <!-- <div class="mb-3">
                    <label for="companyName">{{ $t('auth.companyname') }}</label>
                    <input type="text" class="form-control" id="companyName"
                        :placeholder="$t('auth.companyname_placeholder')" required v-model="companyName"
                        :class="{ 'is-invalid': !isRequired.companyName }" />
                    <label style="font-size: 0.8rem" class="text-muted" v-if="!isRequired.companyName">{{
                            $t('validation.min_char', { amount: 2 })
                    }}</label>
                </div> -->

                <!-- <div class="mb-3">
                    <label for="organisationNr">{{ $t('auth.orgnumber') }}</label>
                    <input type="text" maxlength="11" class="form-control" id="organisationNr" placeholder="xxxxxx-xxxx"
                        required v-model="organisationNr" :class="{ 'is-invalid': !isRequired.organisationNr }"
                        @input="onOrgChange($event)" />
                    <label style="font-size: 0.8rem" class="text-muted" v-if="!isRequired.organisationNr">{{
                            $t('auth.orgnumber_invalid')
                    }}</label>
                </div> -->

                <!-- <div class="row">
                    <div class="col-md-6 mb-3">
                        <label for="firstName">{{ $t('auth.firstname') }}</label>
                        <input type="text" class="form-control" id="firstName" placeholder="" required
                            v-model="firstName" :class="{ 'is-invalid': !isRequired.name }" />
                        <label style="font-size: 0.8rem" class="text-muted" v-if="!isRequired.name">{{
                                $t('auth.firstlastname_required')
                        }}</label>
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="lastName">{{ $t('auth.lastname') }}</label>
                        <input type="text" class="form-control" id="lastName" placeholder="" required v-model="lastName"
                            :class="{ 'is-invalid': !isRequired.name }" />
                        <div class="invalid-feedback">Valid last name is required.</div>
                    </div>
                </div> -->

                <div class="mb-3">
                    <label for="emailReg">{{ $t('auth.email') }}</label>
                    <input type="email" class="form-control" id="emailReg"
                        v-model="email" :class="{ 'is-invalid': !isRequired.email }" />
                    <label style="font-size: 0.8rem" class="text-muted" v-if="!isRequired.name">{{
                            $t('auth.email_invalid')
                    }}</label>
                </div>

                <div class="row mb-4">
                    <div class="col-md-6 mb-6">
                        <label for="password">{{ $t('auth.password') }}</label>
                        <input type="password" class="form-control" id="password" required v-model="password"
                            :class="{ 'is-invalid': !isRequired.password }" />
                        <label style="font-size: 0.8rem" class="text-muted" v-if="!isRequired.password">{{
                                $t('auth.password_requirements')
                        }}</label>
                    </div>
                    <div class="col-md-6 mb-6">
                        <label for="checkPassword">{{ $t('auth.confirm_password') }}</label>
                        <input type="password" class="form-control" id="checkPassword" required v-model="checkPassword"
                            :class="{ 'is-invalid': !isRequired.password }" />
                    </div>
                </div>

                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">+46</span>
                    </div>
                    <input type="tel" class="form-control" id="phoneNr" placeholder="73xxxxxxx" required
                        v-model="phoneNr" :class="{ 'is-invalid': !isRequired.phoneNr }" />
                </div>

                <!-- <div class="mb-3">
                    <label for="address">{{ $t('auth.companyaddress') }}</label>
                    <input type="text" class="form-control" id="address"
                        :placeholder="$t('auth.companyaddress_placeholder')" required v-model="companyAddress"
                        :class="{ 'is-invalid': !isRequired.address }" />
                </div> -->

                <!-- <div class="row mb-4">
                    <div class="col-md-6 mb-6">
                        <label for="zip">{{ $t('auth.postalcode') }}</label>
                        <input type="text" class="form-control" id="zip" placeholder="" required v-model="companyZip"
                            :class="{ 'is-invalid': !isRequired.address }" />
                        <label style="font-size: 0.8rem" class="text-muted" v-if="!isRequired.address">{{
                                $t('auth.fulladdress_required')
                        }}</label>
                    </div>
                    <div class="col-md-6 mb-6">
                        <label for="state">{{ $t('auth.city') }}</label>
                        <input type="text" class="form-control" id="state" placeholder="" required
                            v-model="companyState" :class="{ 'is-invalid': !isRequired.address }" />
                    </div>
                </div> -->
                <div>
                    <div class="checkbox">
                        <label>
                            <input v-model="terms" type="checkbox" id="terms" />
                            {{ $t('general.iaccept') }}
                            <a :class="{ 'is-invalid': !isRequired.terms }" href="/agreements/30202_User-terms_Bokaklipp.pdf" target="_blank">
                                {{ $t('general.termsofuse') }} {{ $t('general.for') }} Bokaklipp
                            </a>
                        </label>
                    </div>
                </div>
                <hr class="mb-4" />
                <div class="next-button">
                    <div class="row justify-content-center" >
                        <Spinner v-if="isLoading" />
                    </div>
                    <button v-if="!isLoading" @click="registerContinue" class="w-100 btn btn-primary mb-4" type="submit">
                        {{ $t('general.nextstep') }}
                    </button>
                </div>
            </div>
            <div v-else>
                <div class="row row-cols-md-1 mb-3 text-center justify-content-center alternatives">
                    <div class="col">
                        <div class="card mb-4 rounded-3 shadow-sm">
                            <div class="card-body" style="margin-left: 1em; min-width: 20em;">
                                <ul class="list-group mt-3 mb-4">
                                    <li class="list-group-item">
                                        1 kr per SMS
                                    </li>
                                    <li class="list-group-item">
                                        Nå befintliga kunder
                                    </li>
                                    <li class="list-group-item">
                                        Företagets namn som avsändare
                                    </li>
                                    <li class="list-group-item">
                                        Statistik över skickade erbjudanden
                                    </li>
                                </ul>
                                <div style="display: flex; min-width: 80%;">
                                    <button @click="onRegisterClick('premium-yearly')" type="button"
                                        class="w-100 btn btn-lg btn-primary" style="margin-right: 0.5em;">
                                        <nobr>Välj månadsbetalning</nobr><br />
                                        <nobr>250 kr/mån</nobr>
                                    </button>
                                    <button @click="onRegisterClick('premium-yearly')" type="button"
                                        class="w-100 btn btn-lg btn-primary" style="margin-left: 0.5em;">
                                        <nobr>Välj årsbetalning</nobr><br />
                                        <nobr>3000 kr/år</nobr>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="text-secondary mb-4" />
                <label class="text-secondary">{{
                        $t('auth.updowngrade_afterregister')
                }}</label>
            </div>
        </form>
        <a @click="$emit('toggle-login-form')" href="#">{{
                $t('auth.alreadyhaveaccount')
        }}</a>
    </div>
</template>

<script>
import {
    passwordRules,
    emailRegex,
    titleRules,
    companyRules,
    organisationRules,
    phoneNumberRules,
} from '../../rules'
import Spinner from '../misc/Spinner.vue'
import { toast } from 'vue3-toastify';

export default {
    components: {
        Spinner,
    },
    data() {
        return {
            email: '',
            password: '',
            checkPassword: '',
            subscription: '',
            title: '',
            firstName: '',
            lastName: '',
            companyName: '',
            organisationNr: '',
            phoneNr: '',
            companyAddress: '',
            companyZip: '',
            companyState: '',
            submitted: false,
            registerStep: true,
            statusMessage: NaN,
            terms: false,
            isLoading: false,
            isRequired: {
                // title: true,
                password: true,
                email: true,
                terms: true,
                // companyName: true,
                // organisationNr: true,
                name: true,
                phoneNr: true,
                // address: true,
            },
        }
    },
    props: {
        status: {
            type: Object,
            default: () => { },
        },
    },
    computed: {
        isTitleFilled() {
            return this.title && this.titleRules.regex.test(this.title)
        },
        isNameFilled() {
            return this.firstName && this.lastName
        },
        isAddressFilled() {
            return this.companyAddress && this.companyZip && this.companyState
        },
        isCompanyValid() {
            return this.companyName && this.companyRules.regex.test(this.companyName)
        },
        passwordsFilled() {
            return this.password && this.checkPassword
        },
        samePasswords() {
            return this.passwordsFilled ? this.password === this.checkPassword : false
        },
        passwordValidation() {
            let errors = []
            for (let condition of this.passwordRules) {
                if (!condition.regex.test(this.password)) {
                    errors.push(condition.message)
                }
            }
            return { valid: errors.length === 0, errors }
        },
        enableSignUp() {
            return false
        },
        isEmailValid() {
            return this.email && this.emailRegex.test(this.email)
        },
        isTermsValid() {
            return this.terms
        },
    },
    methods: {
        onOrgChange(event) {
            this.organisationNr =
                event.target.value.length === 6
                    ? this.organisationNr + '-'
                    : this.organisationNr
        },
        registerContinue() {
            this.isLoading = true
            // this.terms && this.passwordValidation.valid
            //   ? (this.registerStep = false)
            //   : (this.statusMessage =
            //       "Något gick fel, kolla så att allt är korrekt!");

            this.isRequired.terms = this.isTermsValid ? true : false
            this.isRequired.password =
                this.passwordValidation.valid &&
                    this.passwordsFilled &&
                    this.samePasswords
                    ? true
                    : false
            // this.isRequired.title = this.isTitleFilled ? true : false
            // this.isRequired.companyName = this.companyName ? true : false
            this.isRequired.email = this.isEmailValid ? true : false
            // this.isRequired.organisationNr = this.organisationsnummer(
            //     this.organisationNr
            // )
            //     ? true
            //     : false
            // this.isRequired.name = this.isNameFilled ? true : false
            this.isRequired.phoneNr = this.phoneNumber(this.phoneNr) ? true : false
            // this.isRequired.address = this.isAddressFilled ? true : false
            if (Object.values(this.isRequired).every((item) => item === true)) {
                // this.registerStep = false
                // skip subscription selection step
                this.onRegisterClick('premium-yearly')
            } else {
                this.isLoading = false
            }
        },
        onRegisterClick(subscription) {
            console.log('On register subscription click')
            if (Object.values(this.isRequired).every((item) => item === true)) {
                this.submitted = true
                this.$emit('do-signup', {
                    email: this.email,
                    password: this.password,
                    subscription: subscription,
                    title: this.title,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    companyName: this.companyName,
                    organisationNr: this.organisationNr,
                    phoneNr: this.phoneNr,
                    address: {
                        companyAddress: this.companyAddress,
                        companyZip: this.companyZip,
                        companyState: this.companyState,
                    },
                })
            }
        },
        organisationsnummer(nr) {
            this.valid = false

            if (!this.organisationRules.regex.test(nr)) {
                return false
            }

            this.group = RegExp.$1
            this.controldigits = RegExp.$3
            this.alldigits = this.group + RegExp.$2 + this.controldigits

            if (this.alldigits.substring(2, 3) < 2) {
                return false
            }

            var nn = ''

            for (var n = 0; n < this.alldigits.length; n++) {
                nn += (((n + 1) % 2) + 1) * this.alldigits.substring(n, n + 1)
            }

            console.log(nn)

            this.checksum = 0

            for (var f = 0; f < nn.length; f++) {
                this.checksum += nn.substring(f, f + 1) * 1
            }

            return this.checksum % 10 == 0 ? true : false
        },
        phoneNumber(phone) {
            this.valid = false
            let phoneNumber = phone
            if (phoneNumber.substring(-1, 1) === '0') {
                phoneNumber = '46' + phoneNumber.substring(1, phoneNumber.length)
                console.log(phoneNumber)
            }
            if (phoneNumber.length === 9) {
                phoneNumber = '46' + phoneNumber
            }
            if (
                !this.phoneNumberRules.regex.test(phoneNumber) ||
                phoneNumber.length > 11
            ) {
                return false
            } else {
                this.phoneNr = phoneNumber
                return true
            }
        },
    },
    created() {
        this.emailRegex = emailRegex
        this.passwordRules = passwordRules
        this.titleRules = titleRules
        this.companyRules = companyRules
        this.organisationRules = organisationRules
        this.phoneNumberRules = phoneNumberRules
    },
    watch: {
        status: {
            immediate: true,
            handler(newValue) {
                if(newValue.code === 'auth/email-already-in-use') {
                    toast.error(newValue.message);
                    this.isLoading = false;
                }
            }
        }
    }
}
</script>

<style scoped>
.alternatives {
    color: rgb(40, 40, 40);
    width: 800px;
}

.pricing-card-title {
    font-size: 1.5rem;
}

.next-button .btn-primary {
    padding: 10px 14px;
}

.progress {
    height: 3px;
}
</style>
